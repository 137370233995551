import { gql } from 'urql'
import { AvailableMember } from '../types'

const AgencyQueryAvailableMembers = gql<
  {
    membersAvailableList: {
      collection: Array<AvailableMember>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    page?: number
    limit?: number
    search?: string
    fromDatetime?: string
    toDatetime?: string
    timezone: string
    attendeeIds?: Array<number>
    jobId?: number
    jobStageId: number
  }
>`
  query (
    $page: Int
    $limit: Int
    $search: String
    $fromDatetime: ISO8601DateTime
    $toDatetime: ISO8601DateTime
    $timezone: String!
    $attendeeIds: [Int!]
    $jobId: Int
    $jobStageId: Int!
  ) {
    membersAvailableList(
      page: $page
      limit: $limit
      search: $search
      fromDatetime: $fromDatetime
      toDatetime: $toDatetime
      timezone: $timezone
      attendeeIds: $attendeeIds
      jobId: $jobId
      jobStageId: $jobStageId
    ) {
      collection {
        id
        email
        fullName
        avatarVariants
        defaultColour
        roles {
          id
          name
        }
        availableForSchedule
        jobHiringMember
        userTenants {
          userKind
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default AgencyQueryAvailableMembers
