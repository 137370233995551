import { TFunction } from 'i18next'
import { z } from 'zod'
import { removeHTMLTags } from '../../careers/[id]/utilities'

const schemaScheduleInterviewStep2 = (t: TFunction) => {
  return z
    .object({
      sendEmailToCandidate: z.boolean(),
      sendEmailToAttendees: z.boolean(),
      subjectCandidate: z.string().optional(),
      htmlBodyCandidate: z.string().optional(),
      emailTemplate: z
        .object({
          value: z.string()
        })
        .optional(),
      htmlBodyAttendees: z.string().optional(),
      subjectAttendees: z.string().optional(),
      ccAttendees: z.array(z.object({ value: z.string() })).optional(),
      bccAttendees: z.array(z.object({ value: z.string() })).optional(),
      toAttendees: z.array(z.object({ value: z.string() })).optional(),
      to: z.array(z.object({ value: z.string() })).optional()
    })
    .refine(
      (data) => {
        if (data.sendEmailToCandidate === false) {
          return true
        } else {
          return (data?.subjectCandidate?.trim() || '').length > 0
        }
      },
      {
        message: `${t('form:subject_required')}`,
        path: ['subjectCandidate']
      }
    )
    .refine(
      (data) => {
        if (data.sendEmailToAttendees === false) {
          return true
        } else {
          return (data?.subjectAttendees?.trim() || '').length > 0
        }
      },
      {
        message: `${t('form:subject_required')}`,
        path: ['subjectAttendees']
      }
    )
    .refine(
      (data) => {
        if (data.sendEmailToCandidate === false) {
          return true
        } else {
          return (
            removeHTMLTags(data?.htmlBodyCandidate?.trim() || '').length > 0
          )
        }
      },
      {
        message: `${t('form:email_content_required')}`,
        path: ['htmlBodyCandidate']
      }
    )
    .refine(
      (data) => {
        if (data.sendEmailToAttendees === false) {
          return true
        } else {
          return (
            removeHTMLTags(data?.htmlBodyAttendees?.trim() || '').length > 0
          )
        }
      },
      {
        message: `${t('form:email_content_required')}`,
        path: ['htmlBodyAttendees']
      }
    )
}

export default schemaScheduleInterviewStep2
