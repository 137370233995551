import { IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import configuration from '~/configuration'
import QueryEmailTemplates from '../../settings/email-templates/graphql/query-email-templates'
import QueryApplicantRejectedReasons from '../graphql/query-reject-reasons-applicants'
import { IEmailResponseType } from '../../settings/email-templates/types'

const useRejectCandidate = () => {
  const { clientGraphQL } = useContextGraphQL()

  const promiseRejectReason = (params = {} as IPromiseSearchOption) =>
    new Promise<{
      collection: {
        value?: string
        supportingObj?: {
          name: string
        }
        label?: string
        rejectedKind?: string
      }[]
      metadata?: {
        totalCount: number
      }
    }>((resolve) => {
      return clientGraphQL
        .query(QueryApplicantRejectedReasons)
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { applicantRejectedReasonsList } = result.data
          const collection = applicantRejectedReasonsList?.collection || []
          const metadata = applicantRejectedReasonsList?.metadata || {}

          const cloneData = collection.map((item) => {
            return {
              value: item.id,
              supportingObj: {
                name: item.label
              },
              label: item.label,
              rejectedKind: item.rejectedKind
            }
          })

          return resolve({ metadata, collection: cloneData as never[] })
        })
    })

  const promiseEmailTemplates = (params = {} as IPromiseSearchOption) =>
    new Promise<IEmailResponseType>((resolve) => {
      return clientGraphQL
        .query(QueryEmailTemplates, {
          ...params
        })
        .toPromise()
        .then((result: IResponseContextResult<any>) => {
          if (result.error) {
            return resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { emailTemplatesList } = result.data
          const collection = emailTemplatesList?.collection || []
          const metadata = emailTemplatesList?.metadata || {}
          const cloneData = collection.map((item) => {
            return {
              value: item.id,
              name: item.name,
              body: item.body,
              emailKind: item.emailKind,
              default: item.default,
              subject: item.subject,
              createdBy: item.createdBy,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  return {
    promiseRejectReason,
    promiseEmailTemplates
  }
}

export default useRejectCandidate
