import { gql } from 'urql'
import { IJobStages } from '~/lib/features/jobs/types'

const QueryJobInterviewKitsStagesList = gql`
  query ($limit: Int, $page: Int, $jobId: Int) {
    jobIkitStagesList(limit: $limit, page: $page, jobId: $jobId) {
      collection {
        id
        stageTypeId
        stageLabel
        stageGroup
        index
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryJobInterviewKitsStagesList
