import { EventInput } from '@fullcalendar/core'
import { useCallback, useState } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { currentTimeZone, getTimeZone } from '~/core/utilities/common'
import QueryAttendeeEvents from '../graphql/query-attendee-events'
import { AttendeeEvent } from '../types'
import {
  convertTimezone,
  formatDateWithTz
} from '../utilities/helper-schedule-interview'

const useAttendeeEventHook = () => {
  const { clientGraphQL } = useContextGraphQL()
  const [attendeeEvents, setAttendeeEvents] = useState<{
    metadata: { totalCount: number }
    collection: EventInput[]
  }>()

  const fetchAttendeeEvent = useCallback(
    (params: {
      fromDatetime: string
      toDatetime: string
      attendeeIds: Array<number>
      timezone: string
      interviewId?: number
    }) => {
      return clientGraphQL
        .query(QueryAttendeeEvents, params)
        .toPromise()
        .then((result: IResponseContextResult<AttendeeEvent>) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { userCalendarsEventsList } = result.data
          const collection = userCalendarsEventsList?.collection || []
          const metadata = userCalendarsEventsList?.metadata || {}

          const timezone = convertTimezone(currentTimeZone)
          const formatDate = (date: string) =>
            new Date(
              new Date(date).toLocaleString('en-us', {
                timeZone: params?.timezone
              })
            )

          const cloneData = collection.map((item) => ({
            groupId: String(item.id),
            id: String(item.id),
            start: formatDateWithTz(formatDate(item?.fromDatetime), timezone),
            end: formatDateWithTz(formatDate(item?.toDatetime), timezone),
            editable: false,
            durationEditable: false,
            extendedProps: item
          }))

          return setAttendeeEvents({ metadata, collection: cloneData })
        })
    },
    [clientGraphQL]
  )

  return { fetchAttendeeEvent, attendeeEvents, setAttendeeEvents }
}

export default useAttendeeEventHook
