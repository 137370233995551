import { gql } from 'urql'

const QueryTimezoneCollection = gql`
  query {
    publicTimezonesList {
      collection {
        name
        timezone
      }
    }
  }
`

export default QueryTimezoneCollection
