import { gql } from 'urql'

export type QueryTenantLocationVariable = {
  limit?: number
  page?: number
  search?: string
}
const QueryAgencyLocationList = gql<
  {
    companyLocationsList: {
      collection: {
        id: number
        address: string
        country: string
        state: string
        city: string
      }[]
      metadata: {
        totalCount: number
      }
    }
  },
  QueryTenantLocationVariable
>`
  query (
    $limit: Int!
    $page: Int!
    $search: String
    $companyId: Int
    $jobId: Int
  ) {
    companyLocationsList(
      companyId: $companyId
      limit: $limit
      page: $page
      search: $search
      jobId: $jobId
    ) {
      collection {
        id
        address
        country
        state
        city
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryAgencyLocationList
