/* eslint-disable react/display-name */
import {
  AllowFunc,
  CalendarApi,
  ConstraintInput,
  DateInput,
  DateSelectArg,
  DateUnselectArg,
  EventChangeArg,
  EventContentArg,
  EventDropArg,
  EventSourceInput,
  FormatterInput,
  MoreLinkAction
} from '@fullcalendar/core'
import { DateFormatter } from '@fullcalendar/core/internal'
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg
} from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import { format, subDays } from 'date-fns'
import React, { ReactNode, RefObject, useEffect, useState } from 'react'
import { DATE_FORMAT_YYYYMMDD, dayFormatDate, dayShortFormatDate } from '../utilities/format-date'

interface CalendarProps {
  calendarRef?: RefObject<FullCalendar>
  timezone?: string
  height?: string | number
  initialDate?: string
  dataTransform?: EventSourceInput
  now?: DateInput | ((this: CalendarApi) => DateInput)
  renderEventContentTimeGrid?: (eventInfo: EventContentArg) => ReactNode
  renderEventPopoverContentTimeGrid?: (
    eventInfo: EventContentArg,
    closeEvent?: () => void
  ) => ReactNode
  // renderMoreLinkContent?: (args: { num: number }) => ReactNode
  renderMoreLinkContentTimeGrid?: (args: { num: number }) => ReactNode
  moreLinkClickDayGrid?: MoreLinkAction
  moreLinkClickTimeGrid?: MoreLinkAction
  slotDuration?: string
  slotLabelFormat?: FormatterInput | FormatterInput[] | undefined
  onSelectDate?: (arr1: DateSelectArg) => void
  onUnselectDate?: (arr1: DateUnselectArg) => void
  onSelectEvent?: (arr1: unknown) => void
  setValueSearch?: (arr1: { from_date?: string; to_date?: string }) => void
  onDropEvent?: (eventDropInfo: EventDropArg) => Promise<void>
  onChangeEvent?: (eventChangeInfo: EventChangeArg) => Promise<void>
  onEventResize?: (arg: EventResizeDoneArg) => void
  onDateClick?: (dateClickArg: DateClickArg) => void
  LoadingOverlay?: React.ReactElement
  isFetchingEvents?: boolean
  eventMinHeight?: number

  eventDurationEditable?: boolean
  nowIndicator?: boolean
  editable?: boolean
  selectable?: boolean
  selectMirror?: boolean
  selectConstraint?: ConstraintInput
  eventTimeFormat?: FormatterInput | DateFormatter
  onReloadSelectedEvent?: () => void
  onClickCloseButton?: () => void
  selectAllow?: AllowFunc
  getWidthHeaderBar: () => void
  textOverride?: {
    [key: string]: string
  }
  locale?: string
}

const CalendarSelectEvent = (props: CalendarProps) => {
  const {
    calendarRef,
    timezone = 'local',
    textOverride,
    locale,
    height,
    dataTransform = [],
    initialDate,
    eventMinHeight,
    renderEventContentTimeGrid = undefined,
    renderMoreLinkContentTimeGrid,
    moreLinkClickTimeGrid,
    slotDuration = '01:00:00',
    slotLabelFormat = [
      {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }
    ],
    onUnselectDate,
    onSelectDate,
    onSelectEvent,
    setValueSearch,
    onDropEvent,
    onChangeEvent,
    onDateClick,
    onEventResize,
    LoadingOverlay,
    isFetchingEvents,
    eventDurationEditable = false,
    now,
    editable = true,
    selectable = true,
    onClickCloseButton,
    selectAllow,
    getWidthHeaderBar
  } = props
  const [isLoadView, setIsLoadView] = useState<boolean>(false)

  const submitValueSearch = () => {
    const currentStart = calendarRef?.current?.getApi().view.currentStart
    const currentEnd = calendarRef?.current?.getApi().view.currentEnd
    if (setValueSearch) {
      setValueSearch({
        from_date: currentStart
          ? format(currentStart, DATE_FORMAT_YYYYMMDD)
          : undefined,
        to_date: currentEnd
          ? format(subDays(currentEnd, 1), DATE_FORMAT_YYYYMMDD)
          : undefined
      })
    }
  }
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (!isFetchingEvents && isLoadView) {
      timeout = setTimeout(() => setIsLoadView(false), 200)
    }

    return () => clearTimeout(timeout)
  }, [isFetchingEvents, isLoadView])

  return (
    <div className="full-calendar full-calendar-time-slot">
      <style jsx global>{`
        .full-calendar-time-slot .fc-timegrid,
        .full-calendar-time-slot .fc-col-header,
        .full-calendar-time-slot .fc-timegrid-body,
        .full-calendar-time-slot .fc-timegrid-body table {
          width: 880px !important;
        }
        .full-calendar .fc .fc-toolbar.fc-header-toolbar {
          height: 56px !important;
        }
        .fc-timeline-slot-lane:hover {
          background-color: lightgray;
        }
        .fc-timeline-lane.hover {
          background-color: lightgray;
        }
        .full-calendar .fc .fc-timeGridWeek-view .fc-timegrid-slot,
        .full-calendar .fc .fc-timeGridDay-view .fc-timegrid-slot {
          height: 88px !important;
        }
        .full-calendar-time-slot div::-webkit-scrollbar {
          width: 0;
          background: transparent;
        }
        .full-calendar-time-slot div:hover::-webkit-scrollbar {
          width: 2px;
        }
        /* Handle */
        .full-calendar-time-slot div::-webkit-scrollbar-thumb {
          background: #6b7280;
          border-radius: 10px;
        }
      `}</style>
      {isLoadView ? LoadingOverlay : null}
      <FullCalendar
        locale={locale}
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'today,prev,next title',
          right: 'timeGridDay,timeGridWeek closeButton'
        }}
        initialView="timeGridWeek"
        initialDate={initialDate}
        height={height}
        nowIndicator={!isLoadView}
        now={now}
        editable={editable}
        selectable={selectable}
        selectAllow={selectAllow}
        eventOrderStrict={true}
        selectMirror={true}
        dayMaxEvents={true}
        timeZone={timezone}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit'
        }}
        eventResize={onEventResize}
        events={isLoadView ? [] : dataTransform}
        slotLabelInterval="00:30:00"
        snapDuration="00:15:00"
        viewDidMount={(e) => {
          setTimeout(() => {
            e.view?.calendar.updateSize()
          }, 800)
        }}
        titleFormat={{
          month: 'short',
          year: 'numeric'
        }}
        eventOrder={(e) => {
          //@ts-ignore
          return !!e?.extendedProps?.id ? 1 : -1
        }}
        eventDurationEditable={eventDurationEditable}
        eventChange={(eventChangeInfo) => {
          if (onChangeEvent) onChangeEvent(eventChangeInfo)
        }}
        eventClick={(eventClickInfo) => {
          if (onSelectEvent) {
            const extendProps = eventClickInfo.event._def.extendedProps
            onSelectEvent({ ...extendProps, id: extendProps.id })
          }
        }}
        eventDrop={(eventDropInfo) => {
          if (onDropEvent) {
            onDropEvent(eventDropInfo)
          }
        }}
        select={onSelectDate}
        unselect={onUnselectDate}
        {...{
          views: {
            timeGrid: {
              eventContent: (eventInfo) => {
                return (
                  <button type="button" className="flex h-full w-full flex-1">
                    {renderEventContentTimeGrid ? (
                      renderEventContentTimeGrid(eventInfo)
                    ) : (
                      <div className="text-xs">{eventInfo?.event?.title}</div>
                    )}
                  </button>
                )
              },
              slotLabelFormat,
              timeGrid: 47,
              allDaySlot: false,
              eventMaxStack: 2,
              eventMinHeight,
              slotEventOverlap: false,
              titleFormat: {
                month: 'short',
                year: 'numeric'
              },
              slotDuration,
              moreLinkContent: renderMoreLinkContentTimeGrid,
              moreLinkClick: moreLinkClickTimeGrid,
              dayHeaderContent: (args) => {
                const date = new Date(args.date)
                return (
                  <div>
                    <div className="time-grid__day-week">
                      {date && dayShortFormatDate(date)}
                    </div>
                    <div className="time-grid__day-number">
                      {date && dayFormatDate(date)}
                    </div>
                  </div>
                )
              },
              dateClick: onDateClick,
              dayPopoverFormat: {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
              }
            }
          }
        }}
        customButtons={{
          next: {
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().next()
              submitValueSearch()
              getWidthHeaderBar()
            }
          },
          prev: {
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().prev()
              submitValueSearch()
              getWidthHeaderBar()
            }
          },
          today: {
            text: textOverride?.today || 'Today',
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().today()
              submitValueSearch()
              getWidthHeaderBar()
            }
          },
          dayGridMonth: {
            text: textOverride?.month || 'Month',
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().changeView('dayGridMonth')
              submitValueSearch()
              getWidthHeaderBar()
            }
          },
          timeGridDay: {
            text: textOverride?.day || 'Day',
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().changeView('timeGridDay')
              submitValueSearch()
              getWidthHeaderBar()
            }
          },

          timeGridWeek: {
            text: textOverride?.week || 'Week',
            click: () => {
              setIsLoadView(true)
              calendarRef?.current?.getApi().changeView('timeGridWeek')
              submitValueSearch()
              getWidthHeaderBar()
            }
          },
          closeButton: {
            text: '',
            icon: 'x',
            click: onClickCloseButton
          }
        }}
      />
    </div>
  )
}

CalendarSelectEvent.displayName = 'CalendarSelectEvent'

export { CalendarSelectEvent }
export type { CalendarProps }

