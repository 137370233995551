import { FC, useMemo, useRef } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import useEnumsData from 'src/hooks/data/use-enums-data'
import configuration from '~/configuration'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import { Avatar } from '~/core/ui/Avatar'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { DashedButton } from '~/core/ui/DashedButton'
import { DropdownOptionAsync } from '~/core/ui/DropdownOptionAsync'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { Input } from '~/core/ui/Input'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import useCheckAvailableAttendeesHook from '~/lib/features/calendar/hooks/use-check-available-attendees'
import { useQueryTimezone } from '~/lib/features/calendar/hooks/use-query-timezone'
import useScheduleInterviewManagement from '~/lib/features/calendar/hooks/use-schedule-interview-management'
import schemaScheduleInterviewStep1 from '~/lib/features/calendar/schema/validation-schedule-interview-step-1'
import {
  FirstStepFormType,
  InterviewDetailType
} from '~/lib/features/calendar/types'
import { DATE_TIME_TYPE_VALUE } from '~/lib/features/calendar/utilities/enum.cva'
import {
  isDirtyAttendees,
  mappingAgencyGroupHiringTeamAttendee,
  mappingGroupHiringTeamAttendee
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import { mappingScheduleInterview } from '~/lib/features/interviews/mapping/schedule-interview-mapping'
import useBoundStore from '~/lib/store'
import DateTimeInterview from './DateTimeInterview'
import useJobs from '~/lib/features/jobs/hooks/use-jobs'
import { AGENCY_TENANT } from '~/core/constants/enum'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'

const FirstStepForm: FC<{
  onClose: () => void
  onFinish?: (data: FirstStepFormType) => Promise<void>
  nextStep?: (data: FirstStepFormType) => void
  isEditInterview?: boolean
  defaultValue?: FirstStepFormType
  interviewInfo?: InterviewDetailType
  isSubmittingForm?: boolean
}> = ({
  onClose,
  onFinish,
  nextStep,
  defaultValue,
  interviewInfo,
  isEditInterview,
  isSubmittingForm
}) => {
  const { t, i18n } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const divRef = useRef<HTMLDivElement>(null)
  const interviewEvents = useEnumsData({
    enumType: 'InterviewEvent',
    locale: i18n.language
  })

  const { data: timezoneOptions, isLoading: isLoadingTimezoneOptions } =
    useQueryTimezone({
      variables: {},
      shouldPause: false
    })
  const {
    promiseAgencyAttendeesListOptions,
    promiseAttendeesListOptions,
    promiseLocationsOptions,
    promiseStageTypesOptions,
    promiseInterviewKitsOptions
  } = useScheduleInterviewManagement({ interviewInfo })

  const { promiseCompanyLocationOptions } = useJobs()
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const {
    availableAttendees,
    isFetching: isFetchingAvailableAttendee,
    fetchAvailableAttendees
  } = useCheckAvailableAttendeesHook(defaultValue)

  return !isLoadingTimezoneOptions ? (
    <div ref={divRef}>
      <DynamicImportForm
        className="w-full"
        schema={schemaScheduleInterviewStep1(t)}
        defaultValue={{
          timezone: timezoneOptions?.filter(
            (tz) => tz.value === user.timezone
          )?.[0],
          eventType: interviewEvents?.[0],
          locationId: undefined,
          meetingUrl: '',
          remindFeedback:
            interviewInfo?.remindFeedback === undefined
              ? true
              : interviewInfo?.remindFeedback,
          ...defaultValue,
          ...(defaultValue?.timezone?.value
            ? {
                timezone: timezoneOptions?.filter(
                  (tz) => tz.value === defaultValue?.timezone?.value
                )?.[0]
              }
            : {})
        }}
        onSubmit={onFinish}>
        {({ formState, control, setValue, getValues, trigger, reset }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const jobStageIdWatcher = useWatch({
            control,
            name: 'jobStageId'
          })

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const methodWatcher = useWatch({
            control,
            name: 'method'
          })

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const notCaseEditGetPicketTimeSlot = useMemo(() => {
            return (
              (interviewInfo?.interviewTimeSlots || []).filter((i) => i.picked)
                .length == 0
            )
          }, [interviewInfo, isEditInterview])

          return (
            <>
              <div className="mb-1 last:mb-0">
                <FormControlItem
                  labelRequired
                  widthLabel="min-w-[140px]"
                  label={`${t('interview:schedule_modal:stage')}`}
                  iconMenus="Database"
                  mode="horizontal"
                  destructive={
                    formState.errors && !!formState.errors?.jobStageId
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.jobStageId?.message as string)
                  }>
                  {isEditInterview ? (
                    <div className="flex h-8 items-center">
                      <TypographyText className="line-clamp-1 pl-3 text-sm text-gray-900 dark:text-gray-200">
                        {defaultValue?.jobStageId?.supportingObj?.name}
                      </TypographyText>
                    </div>
                  ) : (
                    <Controller
                      control={control}
                      name="jobStageId"
                      defaultValue={undefined}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <AsyncSingleSearchWithSelect
                            promiseOptions={promiseStageTypesOptions}
                            size="sm"
                            variant="ghost"
                            onChange={(newValue) => {
                              onChange(newValue)
                              setValue('jobIkitId', undefined)
                            }}
                            placeholder={`${t('label:placeholder:select')}`}
                            value={value}
                            menuPlacement="bottom"
                            destructive={
                              formState.errors && !!formState.errors?.jobStageId
                            }
                            classNameOverride={{
                              input: 'text-gray-900',
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                          />
                        )
                      }}
                    />
                  )}
                </FormControlItem>
              </div>
              <div className="mb-1 last:mb-0">
                <FormControlItem
                  widthLabel="min-w-[140px]"
                  label={`${t('interview:schedule_modal:type')}`}
                  labelRequired
                  iconMenus="CalendarCheck"
                  mode="horizontal"
                  destructive={
                    formState.errors && !!formState.errors?.eventType
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.eventType?.message as string)
                  }>
                  <Controller
                    control={control}
                    name="eventType"
                    defaultValue={{} as ISelectOption}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <NativeSelect
                          onChange={(value) => onChange(value)}
                          size="sm"
                          value={value}
                          options={interviewEvents}
                          variant="ghost"
                          showClearIndicator={false}
                          classNameOverride={{
                            input: 'text-gray-900',
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-1 last:mb-0">
                <FormControlItem
                  widthLabel="min-w-[140px]"
                  labelRequired
                  label={`${t('interview:schedule_modal:attendees')}`}
                  iconMenus="Users"
                  mode="horizontal"
                  helpIcon={
                    <Tooltip
                      mode="icon"
                      align="start"
                      content={`${t(
                        'interview:schedule_modal:note_attendees'
                      )}`}>
                      <IconWrapper size={14} name="InfoIcon" />
                    </Tooltip>
                  }
                  destructive={
                    formState.errors && !!formState.errors?.attendees
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.attendees?.message as string)
                  }>
                  <Controller
                    control={control}
                    name="attendees"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div className="py-1.5">
                          <div className="flex px-2">
                            {isCompanyKind ? (
                              <DropdownOptionAsync
                                className="w-[320px]"
                                isDisabled={!getValues('jobStageId')}
                                portalContainer={divRef?.current}
                                {...(divRef?.current
                                  ? { dropdownMenuContainer: divRef?.current }
                                  : {})}
                                configSelect={{
                                  loadAsyncWhenRender: true,
                                  placeholder: `${t(
                                    'label:placeholder:select'
                                  )}`,
                                  configSelectOption: {
                                    option: 'checkbox',
                                    avatar: true,
                                    supportingText: ['name', 'description']
                                  },
                                  classNameOverride: {
                                    loadingMessage: `${t('label:loading')}`,
                                    noOptionsMessage: `${t('label:noOptions')}`
                                  },
                                  onChange: (newValue) => {
                                    const currentValue = getValues()
                                    onChange(newValue)

                                    fetchAvailableAttendees(currentValue, {
                                      attendees: (newValue ||
                                        []) as ISelectOption[]
                                    })
                                  },
                                  promiseOptions: (param) => {
                                    const currentValue = getValues()
                                    const formatParam = {
                                      ...param,
                                      ...(interviewInfo?.applicant?.job?.id
                                        ? {
                                            jobId: Number(
                                              interviewInfo?.applicant?.job?.id
                                            )
                                          }
                                        : {}),
                                      timezone: currentValue?.timezone?.value,
                                      jobStageId: Number(
                                        getValues('jobStageId')?.value
                                      )
                                    }
                                    return promiseAgencyAttendeesListOptions(
                                      formatParam
                                    )
                                  },
                                  size: 'sm',

                                  value: value,
                                  isMulti: true,
                                  mappingGroupData: (data) =>
                                    mappingAgencyGroupHiringTeamAttendee(
                                      data,
                                      t
                                    )
                                }}
                                side="bottom"
                                sideOffset={5}
                                trigger={
                                  <Tooltip
                                    content={`${t(
                                      'interview:schedule_modal:add_attendee'
                                    )}`}>
                                    <DashedButton iconMenus="Plus" size="sm" />
                                  </Tooltip>
                                }
                              />
                            ) : (
                              <DropdownOptionAsync
                                className="w-[320px]"
                                portalContainer={divRef?.current}
                                {...(divRef?.current
                                  ? { dropdownMenuContainer: divRef?.current }
                                  : {})}
                                configSelect={{
                                  loadAsyncWhenRender: true,
                                  placeholder: `${t(
                                    'label:placeholder:select'
                                  )}`,
                                  configSelectOption: {
                                    option: 'checkbox',
                                    avatar: true,
                                    supportingText: ['name', 'description']
                                  },
                                  classNameOverride: {
                                    loadingMessage: `${t('label:loading')}`,
                                    noOptionsMessage: `${t('label:noOptions')}`
                                  },
                                  onChange: (newValue) => {
                                    const currentValue = getValues()
                                    onChange(newValue)

                                    fetchAvailableAttendees(currentValue, {
                                      attendees: (newValue ||
                                        []) as ISelectOption[]
                                    })
                                  },
                                  promiseOptions: (param) => {
                                    const currentValue = getValues()
                                    const formatParam = {
                                      ...param,
                                      ...(interviewInfo?.applicant?.job?.id
                                        ? {
                                            jobId: Number(
                                              interviewInfo?.applicant?.job?.id
                                            )
                                          }
                                        : {}),
                                      timezone: currentValue?.timezone?.value
                                    }
                                    return promiseAttendeesListOptions(
                                      formatParam
                                    )
                                  },
                                  size: 'sm',

                                  value: value,
                                  isMulti: true,
                                  mappingGroupData: (data) =>
                                    mappingGroupHiringTeamAttendee(data, t)
                                }}
                                side="bottom"
                                sideOffset={5}
                                trigger={
                                  <Tooltip
                                    content={`${t(
                                      'interview:schedule_modal:add_attendee'
                                    )}`}>
                                    <DashedButton iconMenus="Plus" size="sm" />
                                  </Tooltip>
                                }
                              />
                            )}
                          </div>
                          {(availableAttendees || []).map((attendee, index) => (
                            <div
                              className="group mt-0.5 flex justify-between px-2 py-1.5 hover:bg-gray-50"
                              key={`attendee-${index}`}>
                              <div className="flex items-center space-x-2">
                                <div className="flex-none">
                                  <Avatar
                                    size="xs"
                                    src={attendee?.avatarVariants?.thumb?.url}
                                    alt={attendee?.fullName}
                                    color={attendee?.defaultColour}
                                  />
                                </div>

                                <Tooltip content={attendee?.fullName}>
                                  <div className="line-clamp-1 break-all text-sm text-gray-900">
                                    {attendee?.fullName}
                                  </div>
                                </Tooltip>

                                {!isFetchingAvailableAttendee &&
                                  (methodWatcher ===
                                    DATE_TIME_TYPE_VALUE.specific ||
                                    !!(
                                      interviewInfo?.interviewTimeSlots || []
                                    ).find((i) => i.picked)) &&
                                  (attendee?.availableForSchedule ? (
                                    <Badge
                                      variant="outline"
                                      size="md"
                                      color="green"
                                      type="iconLeading"
                                      iconSVG="CheckCircle">
                                      {t('interview:schedule_modal:available')}
                                    </Badge>
                                  ) : (
                                    <Badge
                                      variant="outline"
                                      size="md"
                                      color="green"
                                      type="iconLeading"
                                      iconSVG="XCircle">
                                      {t('interview:schedule_modal:busy')}
                                    </Badge>
                                  ))}
                              </div>
                              <Tooltip
                                content={`${t(
                                  'interview:schedule_modal:remove_attendee'
                                )}`}>
                                <IconButton
                                  className="invisible ml-2 group-hover:visible"
                                  size="xs"
                                  iconMenus="Trash2"
                                  type="destructive"
                                  onClick={async () => {
                                    if (isEditInterview)
                                      await reset(undefined, {
                                        keepDirtyValues: true,
                                        keepDirty: true
                                      })

                                    const oldValues =
                                      mappingScheduleInterview(interviewInfo)
                                    setValue(
                                      'attendees',
                                      (value || []).filter(
                                        (item) =>
                                          item?.value !== String(attendee?.id)
                                      ),
                                      {
                                        shouldDirty: isDirtyAttendees({
                                          oldData: oldValues?.attendees,
                                          newData: (value || []).filter(
                                            (item) =>
                                              item?.value !==
                                              String(attendee?.id)
                                          )
                                        })
                                      }
                                    )

                                    fetchAvailableAttendees(getValues(), {
                                      attendees: (value || []).filter(
                                        (item) =>
                                          item?.value !== String(attendee?.id)
                                      )
                                    })
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-1 last:mb-0">
                <FormControlItem
                  widthLabel="min-w-[140px]"
                  label={`${t('interview:schedule_modal:location')}`}
                  iconMenus="MapPin"
                  mode="horizontal"
                  destructive={
                    formState.errors && !!formState.errors?.locationId
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.locationId?.message as string)
                  }>
                  <Controller
                    control={control}
                    name="locationId"
                    defaultValue={undefined}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <AsyncSingleSearchWithSelect
                          // promiseOptions={promiseLocationsOptions}
                          promiseOptions={(params) => {
                            return isCompanyKind
                              ? promiseCompanyLocationOptions({
                                  ...params,
                                  jobId: Number(
                                    interviewInfo?.applicant?.job?.id
                                  )
                                })
                              : promiseLocationsOptions(params)
                          }}
                          size="sm"
                          variant="ghost"
                          onChange={onChange}
                          placeholder={`${t(
                            'interview:schedule_modal:select'
                          )}`}
                          value={value}
                          menuPlacement="top"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-1 last:mb-0">
                <FormControlItem
                  widthLabel="min-w-[140px]"
                  label={`${t('interview:schedule_modal:meeting_url')}`}
                  iconMenus="Link"
                  mode="horizontal"
                  destructive={
                    formState.errors && !!formState.errors?.meetingUrl
                  }
                  destructiveText={
                    formState.errors &&
                    (formState.errors.meetingUrl?.message as string)
                  }>
                  <Controller
                    control={control}
                    name="meetingUrl"
                    defaultValue={''}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Input
                          variant="ghost"
                          size="sm"
                          className="w-full"
                          onChange={onChange}
                          value={value}
                          placeholder={`${t(
                            'interview:schedule_modal:input_meeting_url'
                          )}`}
                        />
                      )
                    }}
                  />
                </FormControlItem>
              </div>
              <div className="mb-1 last:mb-0">
                <DateTimeInterview
                  control={control}
                  formState={formState}
                  setValue={setValue}
                  trigger={trigger}
                  reset={reset}
                  timezoneOptions={timezoneOptions}
                  getValues={getValues}
                  interviewInfo={interviewInfo}
                  isEditInterview={isEditInterview}
                  fetchAvailableAttendees={fetchAvailableAttendees}
                />
              </div>

              {!!jobStageIdWatcher ? (
                <div className="mb-1 last:mb-0">
                  {isEditInterview ? (
                    <>
                      {defaultValue?.jobIkitId?.supportingObj?.name ? (
                        <FormControlItem
                          widthLabel="min-w-[140px]"
                          label={`${t(
                            'interview:schedule_modal:interview_kit'
                          )}`}
                          iconMenus="ListChecks"
                          mode="horizontal">
                          <div className="flex h-8 items-center">
                            <TypographyText className="line-clamp-1 pl-3 text-sm text-gray-900 dark:text-gray-200">
                              {defaultValue?.jobIkitId?.supportingObj?.name}
                            </TypographyText>
                          </div>
                        </FormControlItem>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <FormControlItem
                        widthLabel="min-w-[140px]"
                        label={`${t('interview:schedule_modal:interview_kit')}`}
                        iconMenus="ListChecks"
                        mode="horizontal">
                        <Controller
                          control={control}
                          name="jobIkitId"
                          defaultValue={undefined}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <AsyncSingleSearchWithSelect
                                promiseOptions={(params) => {
                                  return promiseInterviewKitsOptions({
                                    ...params,
                                    jobStageId: Number(
                                      getValues().jobStageId?.value
                                    )
                                  })
                                }}
                                size="sm"
                                variant="ghost"
                                onChange={onChange}
                                placeholder={`${t(
                                  'interview:schedule_modal:select'
                                )}`}
                                value={value}
                                menuPlacement="top"
                                classNameOverride={{
                                  loadingMessage: `${t('label:loading')}`,
                                  noOptionsMessage: `${t('label:noOptions')}`
                                }}
                              />
                            )
                          }}
                        />
                        <div className="mt-0.5 pl-3">
                          <div className="flex items-center space-x-1">
                            <IconWrapper size={14} name="Info" />
                            <TypographyText className="text-xs text-gray-600">
                              {t('interview:schedule_modal:add_interview_kit')}
                            </TypographyText>
                            <TextButton
                              size="sm"
                              underline={false}
                              onClick={() => {
                                window.open(
                                  `${configuration.path.jobs.detail(
                                    Number(interviewInfo?.applicant?.job?.id)
                                  )}?tabs=details`,
                                  '_blank'
                                )
                              }}>
                              {t('interview:schedule_modal:here')}
                            </TextButton>
                          </div>
                        </div>
                      </FormControlItem>
                    </>
                  )}
                </div>
              ) : null}

              <div>
                <FormControlItem
                  widthLabel="min-w-[140px]"
                  label={`${t('interview:schedule_modal:reminder')}`}
                  iconMenus="BellRing"
                  mode="horizontal">
                  <div className="space-y-2 pl-3 pt-1.5">
                    <div className="space-y-1">
                      <Controller
                        control={control}
                        name="remindSchedule"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Toggle
                              isChecked={value}
                              name="view-switch"
                              onCheckedChange={(checked) => {
                                onChange(checked)
                              }}
                              size="sm"
                              text={`${t(
                                'interview:schedule_modal:candidateReminder'
                              )}`}
                              toggle="leading"
                              classNameConfig={{
                                contentContainer: 'flex-1'
                              }}
                            />
                          )
                        }}
                      />
                      <TypographyText className="text-xs text-gray-600 dark:text-gray-400">
                        {t(
                          'interview:schedule_modal:descriptionCandidateReminder'
                        )}
                      </TypographyText>
                    </div>
                    <div className="space-y-1">
                      <Controller
                        control={control}
                        name="remindFeedback"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Toggle
                              isChecked={value}
                              name="view-switch"
                              onCheckedChange={(checked) => {
                                onChange(checked)
                              }}
                              size="sm"
                              text={`${t(
                                'interview:schedule_modal:feedback_reminder'
                              )}`}
                              toggle="leading"
                              classNameConfig={{
                                contentContainer: 'flex-1'
                              }}
                            />
                          )
                        }}
                      />
                      <TypographyText className="text-xs text-gray-600 dark:text-gray-400">
                        {t('interview:schedule_modal:send_email_reminder')}
                      </TypographyText>
                    </div>
                  </div>
                </FormControlItem>
              </div>

              <div className="-mx-6 mt-4 flex items-center justify-between border-t border-t-gray-100 px-6 pt-6">
                <TextButton
                  label={`${t('common:learn_more')}`}
                  classNameText="font-normal"
                  underline={false}
                  onClick={() => {
                    window.open(
                      pathConfiguration.helpCenter.schedulePopup,
                      'blank'
                    )
                  }}
                />
                <div className="flex">
                  <Button
                    className="mr-3"
                    type="secondary"
                    size="sm"
                    label={`${t('button:cancel')}`}
                    onClick={onClose}
                  />
                  {methodWatcher === DATE_TIME_TYPE_VALUE.self_schedule &&
                  notCaseEditGetPicketTimeSlot ? (
                    <Button
                      label={`${t(
                        'interview:schedule_modal:send_booking_form'
                      )}`}
                      isLoading={isSubmittingForm}
                      isDisabled={
                        (!formState.isDirty && isEditInterview) ||
                        isSubmittingForm
                      }
                      size="sm"
                      htmlType="submit"
                    />
                  ) : (
                    <Button
                      label={`${t('interview:schedule_modal:continue')}`}
                      size="sm"
                      onClick={() => {
                        trigger(['jobStageId', 'attendees', 'timeSlots']).then(
                          () => {
                            if (Object.keys(formState.errors).length === 0) {
                              const data = getValues()
                              data && nextStep && nextStep(data)
                            }
                          }
                        )
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )
        }}
      </DynamicImportForm>
    </div>
  ) : null
}

export default FirstStepForm
