import { AnyVariables, TypedDocumentNode } from 'urql'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryTimezoneCollection from '../graphql/query-timezone'
import { mappingPublicTimezoneListToSelect } from '../mapping/timezone-mapping'

export function useQueryTimezone({
  variables,
  shouldPause = true
}: {
  variables: object
  shouldPause?: boolean
}) {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query: QueryTimezoneCollection,
    variables,
    shouldPause
  })
  const data = response?.publicTimezonesList.collection || []

  return {
    trigger,
    isLoading,
    data: mappingPublicTimezoneListToSelect(data),
    error
  }
}
