import { useMemo } from 'react'
import { AnyVariables, TypedDocumentNode } from 'urql'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'

export function useQueryEmailTemplatePlaceholderList({
  query,
  variables
}: {
  query: TypedDocumentNode<
    {
      emailTemplatesPlaceholderList: {
        [key: string]: Array<{
          value_key: string
        }>
      }
    },
    AnyVariables
  >
  variables: object
}) {
  const {
    trigger,
    isLoading,
    data: response,
    error
  } = useQueryGraphQL({
    query,
    variables,
    shouldPause: false
  })

  const data = useMemo(
    () => response?.emailTemplatesPlaceholderList,
    [response]
  )

  return {
    trigger,
    isLoading,
    data,
    error
  }
}
