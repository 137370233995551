import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AlertDialog } from '~/core/ui/AlertDialog'
import { useMoreLinkPopover } from '~/lib/features/calendar/hooks/use-get-more-link-popover'

const DiscardUnchangeModal: FC<{
  open: boolean
  onClose: () => void
  onDiscardChange: () => void
  keepScheduleCallback?: () => void
}> = ({ open, onClose, onDiscardChange, keepScheduleCallback }) => {
  const { t } = useTranslation()
  const { moreLinkPopover } = useMoreLinkPopover()
  const keepSchedule = useCallback(() => {
    onClose()
    keepScheduleCallback && keepScheduleCallback()
  }, [keepScheduleCallback, onClose])
  return (
    <div
      {...(!!moreLinkPopover
        ? { onMouseDown: (e) => e.stopPropagation() }
        : {})}>
      <AlertDialog
        open={open}
        destroy={onClose}
        title={`${t('common:modal:discard_unsaved_changes_title')}`}
        description={`${t('common:modal:discard_unsaved_changes_description')}`}
        actions={[
          {
            label: `${t('button:keepScheduling')}`,
            type: 'secondary',
            size: 'sm',
            onClick: keepSchedule
          },
          {
            label: `${t('button:discard')}`,
            type: 'destructive',
            size: 'sm',
            onClick: () => {
              onClose()
              onDiscardChange()
            }
          }
        ]}
      />
    </div>
  )
}

export default DiscardUnchangeModal
