import { gql } from 'urql'
import { IJobInterviewKit } from '../types'

const QueryJobScheduleInterviewKitsList = gql<
  {
    interviewJobIkitsList: {
      collection: IJobInterviewKit[]
      metadata: { totalCount: number }
    }
  },
  {}
>`
  query ($limit: Int, $page: Int, $jobStageId: Int!) {
    interviewJobIkitsList(limit: $limit, page: $page, jobStageId: $jobStageId) {
      collection {
        id
        name
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryJobScheduleInterviewKitsList
