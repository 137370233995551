import { gql } from 'urql'
import { AttendeeEvent } from '../types'

const QueryAttendeeEvents = gql<
  {
    userCalendarsEventsList: {
      collection: Array<AttendeeEvent>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    fromDatetime: string
    toDatetime: string
    attendeeIds: Array<number>
    interviewId?: number
  }
>`
  query (
    $fromDatetime: ISO8601DateTime!
    $toDatetime: ISO8601DateTime!
    $attendeeIds: [Int!]!
    $interviewId: Int
  ) {
    userCalendarsEventsList(
      attendeeIds: $attendeeIds
      fromDatetime: $fromDatetime
      toDatetime: $toDatetime
      interviewId: $interviewId
    ) {
      collection {
        id
        fromDatetime
        toDatetime
        timezone
        eventAttendees {
          id
          email
          fullName
          defaultColour
          avatarVariants
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryAttendeeEvents
