import { useCallback } from 'react'
import configuration from '~/configuration'
import { ILogoAndAvatarVariants } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'

import QueryLocationList from '~/lib/features/calendar/graphql/query-location-list'
import QueryJobInterviewKitsStagesList from '~/lib/features/settings/interview-kits/graphql/query-job-interview-kits-stages-list'
import QueryJobScheduleInterviewKitsList from '~/lib/features/settings/interview-kits/graphql/query-job-schedule-interview-kits-list'
import { IJobInterviewKit } from '~/lib/features/settings/interview-kits/types'
import QueryAvailableMembers from '../graphql/query-available-members'
import { InterviewDetailType } from '../types'
import { IJobStage } from '../../jobs/types'
import QueryAgencyLocationList from '../graphql/query-agency-location-list'
import AgencyQueryAvailableMembers from '../graphql/agency-query-available-members'

const useScheduleInterviewManagement = ({
  interviewInfo,
  companyId
}: {
  interviewInfo?: InterviewDetailType
  companyId?: number
}) => {
  const { clientGraphQL } = useContextGraphQL()

  const promiseAgencyAttendeesListOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(AgencyQueryAvailableMembers, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              avatarVariants?: ILogoAndAvatarVariants
              roles: { name: string }[]
              fullName: string
              email: string
              defaultColour: string
              userTenants: { userKind: string }[]
              availableForSchedule: boolean
              jobHiringMember: boolean
            }>
          ) => {
            if (result.error) {
              return {
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              }
            }

            const { membersAvailableList } = result.data
            const collection = membersAvailableList?.collection || []
            const metadata = membersAvailableList?.metadata || {}

            const cloneData = collection.map((item) => {
              return {
                value: String(item.id),
                avatar: item.avatarVariants?.thumb?.url,
                avatarVariants: item.avatarVariants,
                avatarSize: 'md',
                label: item?.roles?.[0]?.name,
                availableForSchedule: item?.availableForSchedule,
                jobHiringMember: !!item?.jobHiringMember,
                userTenants: item?.userTenants,
                supportingObj: {
                  name: item.fullName || item.email,
                  description: item.email,
                  defaultColour: item.defaultColour,
                  helpName: item?.roles?.[0]?.name
                }
              }
            })
            return {
              metadata,
              collection: cloneData
            }
          }
        ),
    [clientGraphQL]
  )

  const promiseAttendeesListOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(QueryAvailableMembers, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              avatarVariants?: ILogoAndAvatarVariants
              roles: { name: string }[]
              fullName: string
              email: string
              defaultColour: string
              availableForSchedule: boolean
              jobHiringMember: boolean
            }>
          ) => {
            if (result.error) {
              return {
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              }
            }

            const { membersAvailableList } = result.data
            const collection = membersAvailableList?.collection || []
            const metadata = membersAvailableList?.metadata || {}

            const cloneData = collection.map((item) => {
              return {
                value: String(item.id),
                avatar: item.avatarVariants?.thumb?.url,
                avatarVariants: item.avatarVariants,
                avatarSize: 'md',
                label: item?.roles?.[0]?.name,
                availableForSchedule: item?.availableForSchedule,
                jobHiringMember: !!item?.jobHiringMember,
                supportingObj: {
                  name: item.fullName || item.email,
                  description: item.email,
                  defaultColour: item.defaultColour,
                  helpName: item?.roles?.[0]?.name
                }
              }
            })
            return {
              metadata,
              collection: cloneData
            }
          }
        ),
    [clientGraphQL]
  )

  const promiseAgencyLocationsOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(QueryAgencyLocationList, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              address: string
              country: string
              state: string
              city: string
            }>
          ) => {
            if (result.error) {
              return {
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              }
            }

            const { companyLocationsList } = result.data
            const collection = companyLocationsList?.collection || []
            const metadata = companyLocationsList?.metadata || {}

            const cloneData = collection.map((location) => ({
              value: String(location.id),
              supportingObj: {
                name: [
                  location.address,
                  location.city,
                  location.state,
                  location.country
                ]
                  .filter((item) => item)
                  .join(', ')
              }
            }))

            return { metadata, collection: cloneData }
          }
        ),
    [clientGraphQL]
  )

  const promiseLocationsOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(QueryLocationList, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              address: string
              country: string
              state: string
              city: string
            }>
          ) => {
            if (result.error) {
              return {
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              }
            }

            const { tenantLocationsList } = result.data
            const collection = tenantLocationsList?.collection || []
            const metadata = tenantLocationsList?.metadata || {}

            const cloneData = collection.map((location) => ({
              value: String(location.id),
              supportingObj: {
                name: [
                  location.address,
                  location.city,
                  location.state,
                  location.country
                ]
                  .filter((item) => item)
                  .join(', ')
              }
            }))

            return { metadata, collection: cloneData }
          }
        ),
    [clientGraphQL]
  )

  const promiseStageTypesOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(QueryJobInterviewKitsStagesList, {
          ...params,
          jobId: interviewInfo?.applicant?.job?.id
            ? Number(interviewInfo?.applicant?.job?.id)
            : undefined
        })
        .toPromise()
        .then((result: IResponseContextResult<IJobStage>) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { jobIkitStagesList } = result.data
          const collection = jobIkitStagesList?.collection || []
          const metadata = jobIkitStagesList?.metadata || {}

          const cloneData = collection.map((stage) => ({
            value: String(stage.id),
            supportingObj: {
              name: stage.stageLabel
            }
          }))

          return { metadata, collection: cloneData }
        }),
    [clientGraphQL]
  )

  const promiseInterviewKitsOptions = useCallback(
    (params = {}) =>
      clientGraphQL
        .query(QueryJobScheduleInterviewKitsList, params)
        .toPromise()
        .then((result: IResponseContextResult<IJobInterviewKit>) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { interviewJobIkitsList } = result.data
          const collection = interviewJobIkitsList?.collection || []
          const metadata = interviewJobIkitsList?.metadata || {}

          const cloneData = collection.map((interview) => ({
            value: String(interview.id),
            supportingObj: {
              name: interview.name
            }
          }))

          return { metadata, collection: cloneData }
        }),
    [clientGraphQL]
  )
  return {
    promiseAttendeesListOptions,
    promiseLocationsOptions,
    promiseStageTypesOptions,
    promiseInterviewKitsOptions,
    promiseAgencyLocationsOptions,
    promiseAgencyAttendeesListOptions
  }
}

export default useScheduleInterviewManagement
