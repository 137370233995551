import { Editor as CoreEditor } from '@tiptap/core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Control,
  Controller,
  FormState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import { ISelectOption } from '~/core/@types/global'
import { AsyncSingleSearchWithSelect } from '~/core/ui/AsyncSingleSearchWithSelect'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { Input } from '~/core/ui/Input'
import { IExtraToolbar, RichEditor } from '~/core/ui/RichEditor'
import { IPromiseSearchOption } from '~/core/ui/Select'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import {
  ICandidateApplicant,
  IDisabledFieldsEmailForm,
  ITemplateType
} from '~/lib/features/candidates/types'
import { formatContentPlaceholderEmailTemplate } from '~/lib/features/candidates/utilities'
import { removeHTMLTags } from '~/lib/features/careers/[id]/utilities'
import QueryEmailTemplatesPlaceholderList from '~/lib/features/settings/email-templates/graphql/query-email-template-placeholders'
import { useQueryEmailTemplatePlaceholderList } from '~/lib/features/settings/email-templates/hooks/use-query-email-template-placholder'
import {
  IEmailResponseType,
  IEmailTemplate
} from '~/lib/features/settings/email-templates/types'
import DropDownSelectEmailControl from './DropDownSelectEmailControl'
import SelectEmailTemplateControl from './SelectEmailTemplateControl'
import { useTranslation } from 'react-i18next'

const colorState: { [id: string]: string } = {
  default: 'border-gray-300',
  error: 'border-red-300',
  focus: 'border-primary-300'
}
export type IEmailForm = {
  id?: number
  sendRejectEmail?: boolean
  sendEmail?: boolean
  to?: Array<ISelectOption>
  cc?: Array<ISelectOption>
  bcc?: Array<ISelectOption>
  subject?: string
  htmlBody?: string
  jobId?: Array<ISelectOption>
  delayTime?: string
  emailTemplate?:
    | ISelectOption
    | {
        value: number
      }
    | IEmailTemplate
  rejectedReason?: ISelectOption
  profileId?: number
  attachments?: Array<File>
}
interface EmailContentEditor {
  autoFocus?: boolean
  setValue: UseFormSetValue<IEmailForm>
  setError: UseFormSetError<IEmailForm>
  clearErrors: UseFormClearErrors<IEmailForm>
  control: Control
  formState: FormState<IEmailForm>
  templateType?: ITemplateType
  placeholderValue?: { [key: string]: string }
  templatePlaceholderValue?: { [key: string]: string }
  defaultTemplateEmail?: IEmailTemplate
  defaultShowCc?: boolean
  triggerSetDefaultTemplate?: boolean
  disabledFields?: IDisabledFieldsEmailForm
  showFields?: Array<string>
  applicableJobs?: Array<ICandidateApplicant>
  promiseJobsOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  fieldsName?: {
    [key: string]: any
  }
  promiseEmailTemplates?: (
    params?: IPromiseSearchOption
  ) => Promise<IEmailResponseType>
  extraToolbar?: IExtraToolbar
  optionsToSearch?: ISelectOption[]
  disableSearch?: boolean
}

export const defaultFieldsNameComposeEmail = {
  emailTemplate: 'emailTemplate',
  jobId: 'jobId',
  htmlBody: 'htmlBody',
  subject: 'subject',
  to: 'to',
  cc: 'cc',
  bcc: 'bcc'
}

const EmailContentEditor = ({
  autoFocus = false,
  setValue,
  setError,
  clearErrors,
  control,
  formState,
  templateType,
  placeholderValue,
  templatePlaceholderValue,
  defaultTemplateEmail,
  defaultShowCc = false,
  triggerSetDefaultTemplate = true,
  disabledFields,
  showFields = [
    'to',
    'subject',
    'cc',
    'bcc',
    'jobId',
    'emailTemplate',
    'htmlBody'
  ],
  applicableJobs,
  promiseJobsOptions,
  promiseEmailTemplates,
  fieldsName = defaultFieldsNameComposeEmail,
  extraToolbar,
  optionsToSearch,
  disableSearch
}: EmailContentEditor) => {
  const { t } = useTranslation()
  const editorRef = useRef<CoreEditor>()
  const [isShowCc, setShowCc] = useState(defaultShowCc)
  const [isShowBcc, setShowBcc] = useState(false)
  const [isFocused, setFocused] = useState(autoFocus)
  const template = useWatch({
    control,
    name: fieldsName.emailTemplate
  })

  // const currentJob = useWatch({
  //   control,
  //   name: fieldsName.jobId
  // })

  const { data } = useQueryEmailTemplatePlaceholderList({
    query: QueryEmailTemplatesPlaceholderList,
    variables: {
      emailKind: template?.emailKind
        ? template?.emailKind
        : templateType?.includes('event')
        ? 'event'
        : undefined
    }
  })

  useEffect(() => {
    if (template) {
      let { content: contentBody } = formatContentPlaceholderEmailTemplate(
        template.body || '',
        placeholderValue || {}
      )
      let { content: contentSubject, errorKeys } =
        formatContentPlaceholderEmailTemplate(
          template?.subject || '',
          placeholderValue || {}
        )

      clearErrors()
      if (errorKeys.length > 0) {
        setError(fieldsName.htmlBody, {
          type: 'custom',
          message: `${t(
            'candidates:tabs:candidateEmail:placeholdersAreNotSupportedFull',
            {
              items: errorKeys.map((item) => item).join(', '),
              name:
                templatePlaceholderValue &&
                templatePlaceholderValue[errorKeys[0]]
                  ? templatePlaceholderValue[errorKeys[0]]
                  : ''
            }
          )}`
        })
      }

      setValue(fieldsName.htmlBody, contentBody)
      setValue(fieldsName.subject, removeHTMLTags(contentSubject))
      if (editorRef && editorRef.current) {
        editorRef.current.commands.setContent(contentBody || '')
      }
    }
  }, [template])

  const listAllPlaceholder = useMemo(() => {
    let listPlaceholder: Array<ISelectOption> = []
    if (data) {
      Object.keys(data).forEach((key) => {
        data[key].forEach((i: { value_key: string }) =>
          listPlaceholder.push({
            value: i.value_key,
            supportingObj: { name: i.value_key }
          })
        )
      })
    }

    return listPlaceholder
  }, [data])

  const onSelectPlaceholder = useCallback(
    ({ param, editor }: { param: { value: string }; editor: CoreEditor }) => {
      clearErrors()
      const isValueValid = param?.value && placeholderValue
      let infoPlaceholder = isValueValid
        ? placeholderValue[param?.value]
        : undefined

      if (infoPlaceholder) {
        editor.commands.insertContent(` ${infoPlaceholder} `)
        setTimeout(() => {
          editor.chain().focus().unsetColor().run()
        }, 100)
      } else {
        setError(fieldsName.htmlBody, {
          type: 'custom',
          message: `${t(
            'candidates:tabs:candidateEmail:placeholdersAreNotSupported',
            {
              items: param?.value
            }
          )}`
        })
      }
    },
    [editorRef, clearErrors, placeholderValue]
  )

  const onSetDefaultTemplate = useCallback(
    (value: ISelectOption | IEmailTemplate) => {
      if (defaultTemplateEmail) {
        setValue(fieldsName.emailTemplate, {
          ...defaultTemplateEmail,
          value: String(defaultTemplateEmail?.id),
          supportingObj: {
            name: String(defaultTemplateEmail?.name)
          }
        })
      } else {
        setValue(fieldsName.emailTemplate, value)
      }
    },
    [defaultTemplateEmail, setValue]
  )

  const checkDisableField = useCallback((fieldName: string) => {
    return (disabledFields || []).includes(fieldName)
  }, [])

  const hasErrors =
    formState.errors &&
    !!Object.values(fieldsName || {}).find(
      (fieldName) => !!Object.keys(formState.errors).includes(fieldName)
    )
  // (formState.errors.htmlBody || formState.errors.subject)

  const stateBorder = useMemo(() => {
    return isFocused
      ? hasErrors
        ? colorState.error
        : colorState.focus
      : hasErrors
      ? colorState.error
      : colorState.default
  }, [isFocused, hasErrors])

  return (
    <>
      <div
        onClick={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={cn(
          'button-pointer-events-none rounded border-[1px]',
          stateBorder
        )}>
        <div className="p-3">
          {showFields.includes('to') ? (
            <Controller
              control={control}
              name={fieldsName.to}
              render={({ field: { onChange, value } }) => {
                return (
                  <DropDownSelectEmailControl
                    fieldName="to"
                    formState={formState}
                    disabled={checkDisableField('to')}
                    className="flex flex-row items-center"
                    label={`${t('label:to')}`}
                    onChange={onChange}
                    value={value}
                    options={optionsToSearch}
                    rightAction={
                      (!isShowCc || !isShowBcc) && (
                        <div className="flex items-center space-x-3">
                          {!isShowCc && (
                            <TextButton
                              underline={false}
                              type="tertiary"
                              size="md"
                              onClick={() => setShowCc(!isShowCc)}
                              label={`${t('label:cc')}`}
                            />
                          )}
                          {!isShowBcc && showFields.includes('bcc') && (
                            <TextButton
                              underline={false}
                              type="tertiary"
                              size="md"
                              onClick={() => setShowBcc(!isShowBcc)}
                              label={`${t('label:bcc')}`}
                            />
                          )}
                        </div>
                      )
                    }
                    searchBy={['name', 'description']}
                    disableSearch={disableSearch}
                  />
                )
              }}
            />
          ) : null}

          {showFields.includes('cc') ? (
            <If condition={isShowCc}>
              <Controller
                control={control}
                name={fieldsName.cc}
                render={({ field: { onChange, value } }) => (
                  <DropDownSelectEmailControl
                    autoFocus={true}
                    disabled={checkDisableField('cc')}
                    fieldName="cc"
                    formState={formState}
                    className="mt-2 flex flex-row items-center"
                    label={`${t('label:cc')}`}
                    onChange={onChange}
                    value={value}
                    options={optionsToSearch}
                    searchBy={['name', 'description']}
                    disableSearch={disableSearch}
                  />
                )}
              />
            </If>
          ) : null}

          {showFields.includes('bcc') ? (
            <If condition={isShowBcc}>
              <Controller
                control={control}
                name={fieldsName.bcc}
                render={({ field: { onChange, value } }) => (
                  <DropDownSelectEmailControl
                    autoFocus={true}
                    fieldName="bcc"
                    disabled={checkDisableField('bcc')}
                    formState={formState}
                    className="mt-2 flex flex-row items-center"
                    label={`${t('label:bcc')}`}
                    onChange={onChange}
                    value={value}
                    options={optionsToSearch}
                    searchBy={['name', 'description']}
                    disableSearch={disableSearch}
                  />
                )}
              />
            </If>
          ) : null}
        </div>
        <div className="flex flex-row	items-center border-t-[1px] border-gray-300 p-3">
          {showFields.includes('subject') ? (
            <>
              <div className="w-[60px]">
                <p className="m-0 text-sm text-gray-700">
                  {t('label:subject')}
                </p>
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name={fieldsName.subject}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      autoFocus={autoFocus}
                      disabled={checkDisableField('subject')}
                      className="h-5 border-none shadow-none focus:shadow-transparent"
                      size="sm"
                      value={value}
                      onChange={onChange}
                      placeholder={`${t(
                        'label:placeholder:inputEmailSubject'
                      )}`}
                      destructive={
                        formState.errors && !!formState.errors.subject
                      }
                    />
                  )}
                />
              </div>
            </>
          ) : null}

          <div className={showFields.includes('emailTemplate') ? '' : 'hidden'}>
            <Controller
              control={control}
              name={fieldsName.emailTemplate}
              render={({ field: { onChange, value } }) => (
                <SelectEmailTemplateControl
                  templateType={templateType}
                  onChange={onChange}
                  onSetDefaultTemplate={(template) => {
                    triggerSetDefaultTemplate && onSetDefaultTemplate(template)
                  }}
                  promiseEmailTemplatesList={promiseEmailTemplates}
                />
              )}
            />
          </div>
        </div>
        {showFields.includes('jobId') ? (
          <div className="flex flex-row	items-center border-t-[1px] border-gray-300 p-3">
            <div className="w-[60px]">
              <p className="m-0 text-sm text-gray-700">{t('label:job')}</p>
            </div>
            <div className="flex-1">
              <Controller
                control={control}
                name={fieldsName.jobId}
                defaultValue={undefined}
                render={({ field: { onChange, value } }) => {
                  return (
                    <AsyncSingleSearchWithSelect
                      promiseOptions={promiseJobsOptions}
                      size="sm"
                      variant="ghost"
                      onChange={(newValue) =>
                        onChange(newValue ? [newValue] : [])
                      }
                      placeholder={`${t('label:placeholder:select')}`}
                      value={value}
                      configSelectOption={{
                        supportingText: [
                          'name',
                          'shortName',
                          'description',
                          'descriptionHelpName'
                        ]
                      }}
                      classNameOverride={{
                        loadingMessage: `${t('label:loading')}`,
                        noOptionsMessage: `${t('label:noOptions')}`
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>
        ) : null}
        {showFields.includes('htmlBody') ? (
          <div className="border-t-[1px] border-solid border-gray-300">
            <Controller
              control={control}
              name={fieldsName.htmlBody}
              render={({ field: { onChange, value } }) => {
                return (
                  <RichEditor
                    destructive={
                      formState.errors && !!formState.errors.htmlBody
                    }
                    editorRef={(editor: CoreEditor) =>
                      (editorRef.current = editor)
                    }
                    onChange={onChange}
                    className="focus:none min-h-[219px] min-w-[602px] max-w-full rounded-none border-0	shadow-none focus:shadow-transparent"
                    showCount={false}
                    size="sm"
                    content={value}
                    placeholder={`${t(
                      'candidates:placeholder:inputEmailContent'
                    )}`}
                    extraToolbar={extraToolbar} //attachment position is on menuToolbar
                    extraDropdownMenuEditor={{
                      component: {
                        label: (isFocus) => (
                          <span
                            className={`cursor-pointer text-xs font-medium ${
                              isFocus ? 'text-primary-600' : 'text-gray-700'
                            }  hover:text-primary-600`}>
                            {t('button:insert')}
                          </span>
                        ),
                        icon: (
                          <Tooltip
                            align="end"
                            mode="icon"
                            classNameConfig={{
                              content: 'z-[123456789]'
                            }}
                            content={t(
                              'tooltip:thePersonalizationTokenWillPopulate'
                            )}>
                            <div className="cursor-pointer">
                              <IconWrapper name="HelpCircle" size={12} />
                            </div>
                          </Tooltip>
                        )
                      },
                      source: listAllPlaceholder || [],
                      onClick: ({
                        param,
                        editor
                      }: {
                        param: { value: string }
                        editor: CoreEditor
                      }) => onSelectPlaceholder({ param, editor })
                    }}
                  />
                )
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default EmailContentEditor
