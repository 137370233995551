import { useRouter } from 'next/router'
import { useCallback } from 'react'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { IFormAction } from '~/core/ui/Form'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import useBoundStore from '~/lib/store'
import QueryProfileShow from '../graphql/query-profile'
import { ICandidateProfile } from '../types'

export const useQueryExistProfile = () => {
  const router = useRouter()
  const { setToast } = useBoundStore()
  const { clientGraphQL } = useContextGraphQL()
  const fetchProfileAndCheckProfile = useCallback(
    ({
      email,
      links,
      formAction
    }: {
      email?: string
      links?: { links?: Array<string>; _destroy: boolean }
      formAction: IFormAction
    }) => {
      return clientGraphQL
        .query(QueryProfileShow, {
          ...(email ? { email } : {}),
          ...(links && (links?.links || [])?.length > 0 ? { links } : {})
        })
        .toPromise()
        .then(
          (result: {
            error: { graphQLErrors: Array<object> }
            data: { profilesShow: ICandidateProfile }
          }) => {
            if (result.error) {
              return catchErrorFromGraphQL({
                error: result.error,
                setToast,
                router
              })
            }

            const { profilesShow } = result.data
            if (profilesShow?.id) {
              formAction.setError(!!email ? 'email' : 'links', {
                type: 'custom',
                message: `[${profilesShow?.id}, ${
                  profilesShow?.defaultAccessibleApplicantId || 0
                }]`
              })
              return
            }

            return
          }
        )
    },
    []
  )

  return { fetchProfileAndCheckProfile }
}
