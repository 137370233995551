import { gql } from 'urql'

const QueryProfileShow = gql`
  query ($email: String, $links: JSON) {
    profilesShow(email: $email, links: $links) {
      email
      links
      id
      defaultAccessibleApplicantId
    }
  }
`

export default QueryProfileShow
