import configuration from '~/configuration'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import QueryEmailTemplatesAttendees from '../graphql/query-email-templates-attendees'

export function useQueryEmailTemplateAttendees() {
  const { clientGraphQL } = useContextGraphQL()
  const promiseEmailTemplatesAttendees = () =>
    clientGraphQL
      .query(QueryEmailTemplatesAttendees, {})
      .toPromise()
      .then(
        (result: {
          error: object
          data: {
            defaultEventAttendeeEmailTemplate: {
              name: string
              body: string
              subject: string
            }
          }
        }) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { defaultEventAttendeeEmailTemplate } = result.data

          return {
            metadata: { totalCount: 1 },
            collection: [defaultEventAttendeeEmailTemplate]
          }
        }
      )

  return { promiseEmailTemplatesAttendees }
}
