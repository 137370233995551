const MutationUpdateEmailProfile = `
  mutation ($id: Int!, $email: [String!]) {
    profilesUpdate(
      input: {
        id: $id,
        email: $email,
      }
    ) {
      profile {
        id
      }
    }
  }
`

export default MutationUpdateEmailProfile
