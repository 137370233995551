import { gql } from 'urql'

const QueryEmailTemplatesAttendees = gql<
  {
    defaultEventAttendeeEmailTemplate: {
      name: string
      body: string
      subject: string
    }
  },
  {}
>`
  query {
    defaultEventAttendeeEmailTemplate
  }
`

export default QueryEmailTemplatesAttendees
