import { gql } from 'urql'

export const QueryGetDefaultTemplateRejctApplicant = gql`
  query {
    applicantRejectedReasonsList {
      metadata {
        extras
      }
    }
  }
`

const QueryApplicantRejectedReasons = gql<
  {
    applicantRejectedReasonsList: {
      collection: {
        id: number
        label: string
        rejectedKind: string
        rejectedReason: string
      }[]
      metadata: { totalCount: number; extras: object }
    }
  },
  {}
>`
  query {
    applicantRejectedReasonsList {
      collection {
        id
        label
        rejectedKind
        rejectedReason
      }
      metadata {
        totalCount
        extras
      }
    }
  }
`

export default QueryApplicantRejectedReasons
