import { differenceInMinutes } from 'date-fns'
import { t } from 'i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import {
  Control,
  Controller,
  FormState,
  UseFormGetValues,
  UseFormReset,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { Radio } from '~/core/ui/Radio'
import { ISelectOption } from '~/core/ui/Select'
import { SingleDatePicker } from '~/core/ui/SingleDatePicker'
import { TextButton } from '~/core/ui/TextButton'
import { defaultFormatDate, timeFormatDate } from '~/core/utilities/format-date'
import {
  FirstStepFormType,
  InterviewDetailType
} from '~/lib/features/calendar/types'
import {
  DATE_TIME_TYPE_VALUE,
  INTERVIEW_STATE_VALUE
} from '~/lib/features/calendar/utilities/enum.cva'
import {
  convertTimezone,
  formatDateWithTz,
  getTimeSelectOptions,
  isDirtyTimeSlots,
  setValuesCalendar
} from '~/lib/features/calendar/utilities/helper-schedule-interview'
import { mappingScheduleInterview } from '~/lib/features/interviews/mapping/schedule-interview-mapping'
import DateTimeMethodCalendar, {
  DataSubmitCallbackType
} from './DateTimeMethodCalendar'

const SelectedSlots: FC<{
  max?: number
  timeSlots?: DataSubmitCallbackType['timeSlots']
  onRemoveItem?: (itemIndex: number) => void
}> = ({ max = 3, timeSlots = [], onRemoveItem }) => {
  const [showMore, setShowMore] = useState<boolean>(false)

  return (
    <>
      {(showMore ? timeSlots : timeSlots.slice(0, max))?.map(
        (timeSlot, index) => {
          const startDate = timeSlot?.fromDatetime
            ? new Date(
                new Date(timeSlot?.fromDatetime).toLocaleString('en-us', {
                  timeZone: timeSlot?.timezone
                })
              )
            : new Date()
          const endDate = timeSlot?.toDatetime
            ? new Date(
                new Date(timeSlot?.toDatetime).toLocaleString('en-us', {
                  timeZone: timeSlot?.timezone
                })
              )
            : new Date()
          return (
            <div
              key={`time-slot-${index}`}
              className="mt-0.5 text-sm text-gray-900 first:mt-0">
              <div className="group relative inline-flex items-center after:absolute after:-left-2 after:top-0 after:-z-[1] after:hidden after:h-full after:w-[calc(100%_+_16px)] after:rounded after:bg-gray-50 after:content-[''] hover:after:block">
                {timeFormatDate(startDate)} - {timeFormatDate(endDate)},{' '}
                {defaultFormatDate(startDate)}{' '}
                <a
                  className="invisible hover:cursor-pointer group-hover:visible"
                  onClick={() => {
                    onRemoveItem && onRemoveItem(index)
                  }}>
                  <IconWrapper
                    name="X"
                    size={16}
                    className="ml-1 text-gray-600"
                  />
                </a>
              </div>
            </div>
          )
        }
      )}

      {timeSlots?.length > max && (
        <TextButton
          label={!showMore ? t('button:showMore') : t('button:showLess')}
          size="md"
          type="secondary"
          className="mt-0.5"
          underline={false}
          onClick={() => setShowMore(!showMore)}
        />
      )}
    </>
  )
}

export const checkTimeOccurInterview = ({
  startTime,
  endTime
}: {
  startTime?: string
  endTime?: string
}): boolean => {
  const endTimeDate = new Date(`2023/07/06 ${endTime}:00 GMT+0700`)
  const startTimeDate = new Date(`2023/07/06 ${startTime}:00 GMT+0700`)

  return differenceInMinutes(endTimeDate, startTimeDate) > 0
}

const DateTimeInterview: FC<{
  control: Control<FirstStepFormType, any>
  formState: FormState<FirstStepFormType>
  setValue: UseFormSetValue<FirstStepFormType>
  trigger: UseFormTrigger<FirstStepFormType>
  reset: UseFormReset<FirstStepFormType>
  timezoneOptions: ISelectOption[]
  getValues: UseFormGetValues<FirstStepFormType>
  interviewInfo?: InterviewDetailType
  isEditInterview?: boolean
  fetchAvailableAttendees: (
    currentValue: FirstStepFormType,
    newFormValue?: Partial<FirstStepFormType> | undefined
  ) => void
}> = ({
  control,
  formState,
  setValue,
  trigger,
  reset,
  timezoneOptions,
  getValues,
  interviewInfo,
  isEditInterview,
  fetchAvailableAttendees
}) => {
  const { t, i18n } = useTranslation()
  const [openCalendarPopup, setOpenCalendarPopup] = useState<boolean>(false)
  const startTimeWatcher = useWatch({
    control,
    name: 'startTime'
  })

  const endTimeWatcher = useWatch({
    control,
    name: 'endTime'
  })

  const timeDateMethod = useWatch({
    control,
    name: 'method'
  })
  const timeOptions = useMemo<Array<ISelectOption>>(
    () => getTimeSelectOptions({}),
    []
  )

  const slotTimeWatcher = useWatch({
    control,
    name: 'timeSlots'
  })

  const caseEditGetPickedTimeSlot = useMemo(() => {
    return (
      (interviewInfo?.interviewTimeSlots || []).filter((i) => i.picked).length >
      0
    )
  }, [interviewInfo])

  const handleUpdateData = useCallback(
    async (data: DataSubmitCallbackType) => {
      fetchAvailableAttendees(getValues(), data)

      const oldValues = mappingScheduleInterview(interviewInfo)
      if (isEditInterview) await reset(oldValues)
      setValuesCalendar({
        interviewInfo,
        newData: data,
        setValue,
        caseEditGetPickedTimeSlot
      })
    },
    [
      fetchAvailableAttendees,
      getValues,
      isEditInterview,
      reset,
      interviewInfo,
      setValue,
      caseEditGetPickedTimeSlot
    ]
  )

  const updateTimeSlots = useCallback(
    (timezoneValue?: string) => {
      const interviewTz = convertTimezone(timezoneValue)

      const timeSlotsConvert = (slotTimeWatcher || []).map((timeSlot) => {
        const startDate = timeSlot.fromDatetime
          ? new Date(
              new Date(timeSlot.fromDatetime).toLocaleString('en-us', {
                timeZone: timeSlot.timezone
              })
            )
          : new Date()
        const endDate = timeSlot.toDatetime
          ? new Date(
              new Date(timeSlot.toDatetime).toLocaleString('en-us', {
                timeZone: timeSlot.timezone
              })
            )
          : new Date()
        return {
          ...timeSlot,
          timezone: timezoneValue,
          fromDatetime: formatDateWithTz(startDate, interviewTz),
          toDatetime: formatDateWithTz(endDate, interviewTz)
        }
      })
      setValue('timeSlots', timeSlotsConvert, { shouldDirty: false })
    },
    [slotTimeWatcher]
  )

  return (
    <>
      <FormControlItem
        widthLabel="min-w-[140px]"
        label={`${t('interview:schedule_modal:time_and_date')}`}
        labelRequired
        iconMenus="Clock"
        mode="horizontal"
        destructive={formState.errors && !!formState.errors.startTime}
        destructiveText={
          formState.errors && (formState.errors.startTime?.message as string)
        }>
        <div className="py-1">
          <div className="mb-0.5 px-2">
            <Controller
              control={control}
              name="method"
              defaultValue={undefined}
              render={({ field: { onChange, value } }) => {
                return (
                  <Radio
                    orientation="horizontal"
                    isDisabled={isEditInterview}
                    size="sm"
                    rootClassName="space-x-8"
                    source={Object.values(DATE_TIME_TYPE_VALUE)?.map(
                      (method) => ({
                        text:
                          method === DATE_TIME_TYPE_VALUE.self_schedule ? (
                            method === value &&
                            (slotTimeWatcher || [])?.length > 0 &&
                            !caseEditGetPickedTimeSlot ? (
                              <Trans
                                i18nKey="interview:schedule_modal:candidate_self_schedule_active"
                                values={{
                                  num: (slotTimeWatcher || [])?.length
                                }}>
                                <span className="text-gray-600" />
                              </Trans>
                            ) : (
                              `${t(
                                'interview:schedule_modal:candidate_self_schedule'
                              )}`
                            )
                          ) : (
                            `${t('interview:schedule_modal:set_specific_time')}`
                          ),
                        value: method
                      })
                    )}
                    onValueChange={(value) => {
                      onChange(value)
                      fetchAvailableAttendees(getValues())
                    }}
                    value={value}
                  />
                )
              }}
            />
          </div>
          {timeDateMethod === DATE_TIME_TYPE_VALUE.specific ||
          (isEditInterview && caseEditGetPickedTimeSlot) ? (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div>
                    <Controller
                      control={control}
                      name="startTime"
                      defaultValue={undefined}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NativeSelect
                            classNameOverride={{
                              menu: '!min-w-[96px]',
                              input: 'text-gray-900',
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                            onChange={(newValue) => {
                              const posStartTimeIndex = timeOptions.findIndex(
                                (time) =>
                                  time.value ===
                                  (newValue as ISelectOption)?.value
                              )

                              if (
                                !checkTimeOccurInterview({
                                  startTime: (newValue as ISelectOption)?.value,
                                  endTime: endTimeWatcher?.value
                                })
                              ) {
                                setValue(
                                  'endTime',
                                  (posStartTimeIndex === 0 ||
                                    posStartTimeIndex) &&
                                    timeOptions[posStartTimeIndex + 1]
                                    ? timeOptions[posStartTimeIndex + 1]
                                    : value
                                )
                                fetchAvailableAttendees(getValues(), {
                                  startTime: newValue as ISelectOption,
                                  endTime:
                                    (posStartTimeIndex === 0 ||
                                      posStartTimeIndex) &&
                                    timeOptions[posStartTimeIndex + 1]
                                      ? timeOptions[posStartTimeIndex + 1]
                                      : value
                                })
                              }

                              onChange(newValue)
                              fetchAvailableAttendees(getValues(), {
                                startTime: newValue as ISelectOption
                              })
                            }}
                            size="sm"
                            value={value}
                            options={timeOptions}
                            variant="ghost"
                            showClearIndicator={false}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="mx-1 text-gray-400">-</div>
                  <div>
                    <Controller
                      control={control}
                      name="endTime"
                      defaultValue={undefined}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <NativeSelect
                            onChange={(value) => {
                              onChange(value)
                              fetchAvailableAttendees(getValues(), {
                                endTime: value as ISelectOption
                              })
                            }}
                            classNameOverride={{
                              menu: '!min-w-[96px]',
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                            size="sm"
                            value={value}
                            options={timeOptions.filter((time) => {
                              return checkTimeOccurInterview({
                                startTime: startTimeWatcher?.value,
                                endTime: time.value
                              })
                            })}
                            variant="ghost"
                            showClearIndicator={false}
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="mx-2 h-0.5 w-0.5 rounded bg-gray-400" />
                  <FormControlItem>
                    <Controller
                      control={control}
                      name="date"
                      defaultValue={undefined}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SingleDatePicker
                            locale={i18n.language}
                            className="w-full"
                            config={{
                              defaultOpen: false,
                              onChange: (value) => {
                                onChange(value)
                                fetchAvailableAttendees(getValues(), {
                                  date: value
                                })
                              },
                              value: value,
                              showClearIndicator: false
                            }}
                            placeholder={`${t('label:placeholder:selectDate')}`}
                            size="sm"
                            variant="ghost"
                          />
                        )
                      }}
                    />
                  </FormControlItem>
                </div>
              </div>

              <div>
                <Controller
                  control={control}
                  name="timezone"
                  defaultValue={{} as ISelectOption}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructive={
                          formState.errors && !!formState.errors?.timezone
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors.timezone?.message as string)
                        }>
                        <NativeSelect
                          showClearIndicator={false}
                          configSelectOption={{
                            supportingText: ['name']
                          }}
                          onChange={(value) => {
                            onChange(value)
                            fetchAvailableAttendees(getValues(), {
                              timezone: value as ISelectOption
                            })
                          }}
                          size="sm"
                          value={value}
                          options={timezoneOptions}
                          variant="ghost"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                        />
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mt-1 px-2 pb-1">
                <div className="mb-1">
                  <FormControlItem
                    destructive={
                      formState.errors && !!formState.errors?.timeSlots
                    }
                    destructiveText={
                      formState.errors &&
                      (formState.errors.timeSlots?.message as string)
                    }>
                    <SelectedSlots
                      timeSlots={slotTimeWatcher}
                      onRemoveItem={async (indexRemoveItem) => {
                        if (isEditInterview) await reset(getValues())
                        const oldValues =
                          mappingScheduleInterview(interviewInfo)
                        setValue(
                          'timeSlots',
                          (slotTimeWatcher || []).filter(
                            (_, index) => index !== indexRemoveItem
                          ),
                          {
                            shouldDirty: isDirtyTimeSlots({
                              newData: (slotTimeWatcher || []).filter(
                                (_, index) => index !== indexRemoveItem
                              ),
                              oldData: oldValues.timeSlots
                            })
                          }
                        )
                      }}
                    />
                  </FormControlItem>
                </div>
                {(slotTimeWatcher || [])?.length > 0 && (
                  <Controller
                    control={control}
                    name="timezone"
                    defaultValue={{} as ISelectOption}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem
                          destructive={
                            formState.errors && !!formState.errors?.timezone
                          }
                          destructiveText={
                            formState.errors &&
                            (formState.errors.timezone?.message as string)
                          }>
                          <NativeSelect
                            showClearIndicator={false}
                            configSelectOption={{
                              supportingText: ['name']
                            }}
                            onChange={(value) => {
                              onChange(value)
                              updateTimeSlots((value as ISelectOption)?.value)
                            }}
                            size="sm"
                            value={value}
                            options={timezoneOptions}
                            variant="ghost"
                            classNameOverride={{
                              valueContainer: 'pl-0',
                              // control: '!min-h-[20px]',
                              singleValue: '[&_p]:!text-gray-600',
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                          />
                        </FormControlItem>
                      )
                    }}
                  />
                )}
              </div>
            </>
          )}
          <If
            condition={
              interviewInfo?.state === INTERVIEW_STATE_VALUE.schedule_failed
            }>
            <div className="mb-2 ml-2 mt-1 flex">
              <Badge color={'gray'} radius="circular" size="md">
                {interviewInfo?.stateDescription}
              </Badge>
            </div>
          </If>
          <div className="mt-1 px-2">
            <Button
              label={`${t('interview:schedule_modal:calendar_view')}`}
              size="xs"
              type="secondary"
              iconMenus="ChevronRight"
              icon="trailing"
              onClick={() => setOpenCalendarPopup(true)}
            />
          </div>
        </div>
      </FormControlItem>
      <DateTimeMethodCalendar
        caseEditGetPickedTimeSlot={caseEditGetPickedTimeSlot}
        isEditInterview={isEditInterview}
        open={openCalendarPopup}
        onClose={() => {
          trigger(['timeSlots', 'attendees']).then(() => {
            setOpenCalendarPopup(false)
          })
        }}
        getValues={getValues}
        interviewInfo={interviewInfo}
        onSubmitCallback={(data) => handleUpdateData(data)}
        timezoneOptions={timezoneOptions}
      />
    </>
  )
}

export default DateTimeInterview
