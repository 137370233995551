import { ISelectOption } from '~/core/@types/global'

interface ITimezone {
  name?: string
  timezone?: string
}

export const mappingPublicTimezoneListToSelect = (
  timezoneOptions: Array<ITimezone>
) => {
  return timezoneOptions.map<ISelectOption>((item) => ({
    value: String(item.timezone),
    label: item.name?.match(/[-\+][0-9]*:[0-9][0-9]/)?.[0] || '',
    supportingObj: {
      name: `${item.name || ''}`
    }
  }))
}
