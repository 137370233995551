import { gql } from 'urql'

const QueryEmailTemplates = gql<
  {
    EmailTemplatesList: {
      collection: {
        id: number
        name: string
        body: string
        emailKind: string
        subject: string
        default: boolean
        createdBy: { id: number; email: string }
      }[]
    }
  },
  {}
>`
  query ($limit: Int!, $page: Int!, $search: String, $emailKind: [EmailKind!]) {
    emailTemplatesList(
      limit: $limit
      page: $page
      search: $search
      emailKind: $emailKind
    ) {
      collection {
        id
        name
        body
        emailKind
        default
        subject
        createdBy {
          id
          email
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryEmailTemplates
